import React, { useCallback, useEffect, useState } from 'react';
import axios from 'axios';

import { withStyles } from "@material-ui/core";
import Typography from '@material-ui/core/Typography';

import UpcomingEvent from '../../atoms/UpcomingEvent';

const styles = (theme) => ({
})

function UpcomingEvents(props) {
  const { theme } = props;

  const [eventsState, setEventsState] = useState({
    loading: true,
    events: [
      {
        upcomingEvent: {
          id: 0,
          name: '',
          datetime_start: '',
          datetime_end: '',
          signup_options: {
            tickets: {
              enabled: 1,
              url: '',
            },
          },
        },
      },
    ],
  });

  const loadEvents = useCallback(() => {
    setEventsState(prevState => {
      return {...prevState, loading: true}
    });
    const apiUrl = "https://christchurchlondon.churchsuite.co.uk/embed/calendar/json?category=12&num_results=4&date_end=2025-12-31"
    axios.get(apiUrl)
      .then((response) => {
        const eventsData = response.data;
        setEventsState({ loading: false, events: eventsData });
      })
      .catch(() => {
        console.log("No events data loaded!")
      });
  }, []);

  useEffect(() => {
    loadEvents();
  }, [loadEvents]);

  const eventList = eventsState.events.map((upcomingEvent) => {
    const isLoading = eventsState.loading;
    const upcomingEventSmash = upcomingEvent.id * 13;
    return <UpcomingEvent key={upcomingEventSmash} upcomingEventSmash={upcomingEventSmash} upcomingEvent={upcomingEvent} loading={isLoading} />;
  });

  return (
    <>
      <Typography variant="h4" style={{ marginBottom: theme.spacing(2) }}>
        Upcoming Events
      </Typography>
      {eventList}
    </>
  )
}

export default withStyles(styles, { withTheme: true })(UpcomingEvents)
